$q: '.quiz';

#{$q} {
    height: calc(100vh - var(--footer-h) - var(--header-h));
    display: flex;
    justify-content: space-between;
    align-items: stretch;

    @include media('<desktop') {
        flex-direction: column;
        min-height: calc(100vh - var(--footer-h) - var(--header-h));
        height: calc(var(--quiz-h) + 6.8rem);
        transition-duration: .5s;
        overflow: hidden;
    }

    &__sidebar {
        flex: 36.6rem 0 0;
        color: $white;
        background-color: #009c8c;
        padding: 3.6rem 3.2rem;
        display: flex;
        flex-direction: column;
        position: relative;
        z-index: 2;
        overflow: auto;

        @include media('<desktop') {
            padding: 1.4rem 1.6rem;
            flex: 6.8rem 0 0;
        }

        .logo {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 2.4rem;
            font-size: 1.1rem;
            text-transform: uppercase;
            //ff gotham
            font-weight: 700;//400
            text-decoration: none;
            margin: 0 0 4.8rem;

            &:hover {
                color: inherit;
            }

            @include media('<desktop') {
                margin: 0 auto;
            }
        }

        .prev-answers {
            &--list {
                display: block;
                margin: 0;
                padding: 0;

                > li {
                    display: block;
                    margin: 0 0 1.6rem;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            &--btn {
                background-color: var(--secondary-5);
                border: 0;
                border-radius: .8rem;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                padding: 1.2rem 1.6rem;
                color: #fff;
                min-height: 6.2rem;
                width: 100%;
                transition-duration: .2s;
                text-align: left;
                position: relative;

                .icon-alarm {
                    position: absolute;
                    border: .2rem solid;
                    border-radius: 50%;
                    width: 2.4rem;
                    height: 2.4rem;
                    right: 1rem;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 1.4em;
                }

                &[aria-current='step'],
                &:hover {
                    //background-color: var(--secondary-3);
                    background-color: var(--primary);
                }

                .question {
                    display: block;
                    text-transform: uppercase;
                    font-size: 1.1rem;
                    line-height: 1.65rem;
                }

                .answer {
                    display: block;
                    font-size: 1.4rem;
                    font-weight: 700;
                    line-height: 2.1rem;
                }
            }
        }
    }

    &__container {
        flex: 100% 1 1;
        position: relative;
    }

    &__question {
        display: flex;
        flex-direction: column;
        width: 100%;
        transition: .5s;
        position: absolute;
        left: -100%;
        visibility: hidden;
        height: 100%;
        overflow: auto;

        &.show {
            left: 0;
            visibility: visible;

            & ~ #{$q}__question {
                left: 100%;
            }
        }

        &--top {
            //ff gotham
            font-size: 1.6rem;
            font-weight: 300;//325
            font-style: italic;
            color: var(--secondary-3);
            background-color: var(--grey-200);
            padding: 1.8rem;
            text-align: center;

            @include media('<tablet') {
                padding: 1.2rem;
                font-size: 1.1rem;
                flex: auto 0 0;
            }
        }

        &--inner {
            padding: 6.4rem 9.6rem 3.6rem;

            @include media('<tablet') {
                padding: 3.2rem 2rem 4.8rem;
                flex: 100% 1 1;
            }
        }

        &--title {
            color: var(--grey-800);
            margin: 0 0 3.6rem;
            //ff gotham
            //font-weight: 400;

            @include media('<tablet') {
                margin: 0 0 2rem;
            }

            &:before {
                counter-reset: step var(--step);
                content: counter(step)'. ';
            }
        }

        &--info {
            display: none;
            flex: 100% 0 0;
            order: 999;
            padding: 2.4rem 1.8rem;
            border-radius: .8rem;
            background-color: var(--secondary-6);
            color: var(--secondary-3);
            font-size: 1.6rem;
            line-height: 1.5;
            margin: 0;

            &--checkboxes {
                .inner {
                    display: flex;
                    gap: 1.6rem;
                    justify-content: flex-start;
                    align-items: flex-start;
                    flex-wrap: wrap;
                }
            }

            #{$q}__question--prefinish & {
                display: block;
            }

            #{$q}__question--info {
                display: block;
                margin: 0 0 3.6rem;
            }

            p {
                margin: 2.4rem 0;
            }

            :first-child {
                margin-top: 0;
            }

            :last-child {
                margin-bottom: 0;
            }

            .col {
                > .icon {
                    margin-top: .2rem;
                    font-size: 1.2em;
                }
            }
        }

        &--answers {
            display: flex;
            flex-wrap: wrap;
            gap: 3.6rem 1.6rem;

            @include media('<tablet') {
                flex-direction: column;
                gap: 1.2rem;
            }

            input {
                //display: none;
                opacity: 0;
                height: 0;
                width: 0;
                position: absolute;

                &:focus-visible {
                    & + label {
                        outline-offset: .1rem;
                        outline: -webkit-focus-ring-color auto 1px;
                    }
                }

                & + label {
                    min-height: 5.6rem;
                    min-width: 11rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 1.2rem;
                    padding: 0 2.2rem;
                    border-radius: .8rem;
                    color: var(--grey-800);
                    cursor: pointer;
                    font-size: 1.6rem;
                    font-weight: 600;
                    transition-duration: .2s;
                    background-color: #fff;
                    box-shadow: 0 .4rem 1.6rem 0 rgba(193, 201, 214, .35);

                    .icon {
                        color: var(--primary);
                        transition-duration: .2s;

                        &-circle {
                            background-color: var(--primary);
                            color: #fff;
                            width: 3.6rem;
                            height: 3.6rem;
                            border-radius: 50%;
                            font-size: 2.1rem;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                }

                &:checked {
                    & + label {
                        background-color: var(--primary);
                        color: $white;

                        .icon {
                            color: $white;

                            &-circle {
                                background-color: $white;
                                color: var(--primary);
                            }
                        }

                        & + #{$q}__question--info {
                            display: block;

                            & + #{$q}__question--info--checkboxes {
                                display: block;
                            }
                        }

                        & + #{$q}__question--info--checkboxes {
                            display: block;
                        }
                    }
                }
            }

            &.changed {
                input {
                    &[type='radio'] {
                        &:not(:checked) {
                            & + label {
                                background-color: var(--grey-300);
                                color: #969caf;

                                .icon {
                                    color: inherit;

                                    &-circle {
                                        background-color: #969caf;
                                        color: var(--grey-300);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &--footer {
            justify-content: space-between;
            display: flex;
            padding: 0 9.6rem 3.6rem;
            gap: 1.6rem;

            @include media('<tablet') {
                padding: 0 2rem 4.8rem;
                flex: auto 0 0;
                gap: 1.2rem;

                .btn {
                    flex: calc(50% - .6rem) 0 0;

                    .quiz__question--prefinish & {
                        flex: 100% 0 0;
                    }
                }

                .quiz__question--prefinish & {
                    flex-direction: column;
                }
            }

            .quiz__question--prefinish &,
            .finish & {
                justify-content: flex-end;
            }

            .btn--outline {
                color: var(--primary);
            }

            .prev {
                display: none;

                @include media('<desktop') {
                    display: block;
                }
            }

            .next {
                justify-self: flex-end;
                margin-left: auto;
            }
        }

        @include media('<desktop') {
            height: auto;
        }
    }
}
