.hidden-from--desktop {
    @include media('>=desktop') {
        display: none !important;
    }
}

.hidden-from--medium {
    @include media('>=medium') {
        display: none !important;
    }
}

.hidden-from--tablet {
    @include media('>=tablet') {
        display: none !important;
    }
}

.hidden-from--mobile {
    @include media('>=mobile') {
        display: none !important;
    }
}

.hidden-until--desktop {
    @include media('<desktop') {
        display: none !important;
    }
}

.hidden-until--medium {
    @include media('<medium') {
        display: none !important;
    }
}

.hidden-until--tablet {
    @include media('<tablet') {
        display: none !important;
    }
}

.hidden-until--mobile {
    @include media('<mobile') {
        display: none !important;
    }
}
