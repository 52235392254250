/// Default list styles
///  @group liste

li {
    margin: 0 0 rem($spacer-xxs) rem($spacer-s);

    &::marker {
        color: $color-primary;
    }

    ul,
    ol {
        margin-bottom: rem($spacer-xs);

        li {
            font-size: rem(14px);
        }
    }
}

/// .bullet-list
///@group liste
.bullet-list,
%bullet-list {
    li {
        ul {
            list-style-type: disc;
        }
    }
}

@include dark-bg {
    li {
        &::marker {
            color: inherit;
        }
    }
}

/// .ol-list
///@group liste
.ol-list,
%ol-list {
    > li {
        counter-increment: ol-counter;

        &::marker {
            font-weight: bold;
        }
    }

    ol {
        counter-reset: ol-counter-bis;

        li {
            counter-increment: ol-counter-bis;

            &::marker {
                content: counter(ol-counter) '.' counter(ol-counter-bis) '. ';
            }
        }
    }
}

/// marker square
///@group liste
.list--square {
    li {
        list-style: square;
    }
}
/// marker $color-secondary
///@group liste
.list--secondary {
    li {
        &::marker {
            color: $color-secondary;
        }
    }
}
/// inline list
///@group liste
.list--inline {
    li {
        display: inline flow-root list-item;
        margin-right: 2px; // visually center dot
    }
}
///  reset list styles
///@group liste
.list--unstyled,
%list--unstyled {
    margin-bottom: 0;
    list-style: none;

    li {
        margin: 0;
        padding: 0;

        ul {
            list-style: none;
        }
    }
}
