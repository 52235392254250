.profile {
    margin: 0 0 4.8rem;
    border: .1rem solid #fff;
    border-radius: .8rem;
    padding: 1.2rem 1.6rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1.2rem;

    .text {
        flex: 100% 0 0;
        font-size: 1.4rem;
        line-height: 1.5;

        p {
            margin: 0;

            &.markup {
                font-size: 1.1rem;
                text-transform: uppercase;
            }
        }
    }
}
