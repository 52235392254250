////
/// @group buttons
////

button {
    cursor: pointer;
    font-family: $default-fonts;

    &.link {
        color: var(--primary);
        //padding: 0;
        background: transparent;
        border: 0;

        * {
            pointer-events: none;
        }
    }
}

.btn,
%btn {
    --btn-text: #{$white};
    --btn-color: #{$color-primary};

    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 2.5rem;
    height: 4.8rem;
    background-color: var(--btn-color);
    border: solid 1px var(--btn-color);
    border-radius: rem($btn-radius);
    color: var(--btn-text);
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 1.2;
    text-decoration: none;
    transition: .2s;
    appearance: none;

    .icon {
        transition: .2s;
    }

    &:hover {
        &:not([disabled], .btn--disabled) {
            filter: brightness(90%);
        }
    }

    &--secondary {
        --btn-color: #{$color-secondary};
        color: var(--grey-800);

        .icon {
            color: var(--btn-color);
        }
    }

    &--tertiary {
        --btn-color: #{$color-tertiary};
    }

    &--disabled,
    &[disabled] {
        cursor: not-allowed;
        --btn-color: #{$light-grey};
        --btn-text: #{$grey};
    }

    &--outline {
        background-color: $white;
        color: var(--grey-800);
        border-color: var(--btn-color);

        &.btn--disabled,
        &[disabled] {
            --btn-color: #{$grey};
            filter: none;
        }

        &:hover:not(:disabled, .btn--disabled) {
            background-color: var(--btn-color);
            color: $white;

            .icon {
                color: $white;
            }
        }
    }

    &--small {
        font-size: rem(14px);
        padding: rem(8px) rem(35px);
    }

    &--ir,
    &--il {
        align-items: center;
        justify-content: center;
    }

    &--ir {
        [class^='icon-'] {
            margin-left: rem(8px);
        }
    }

    &--il {
        [class^='icon-'] {
            margin-right: rem(8px);
        }
    }

    [class^='icon-'] {
        font-size: rem(24px);
    }

    &--reset {
        background: none;
        border: none;
        color: inherit;
    }

    &--borderless {
        border-radius: .8rem;
        display: flex;
        flex-direction: column;
        gap: 1.2rem;
        height: auto;
        padding: 1.6rem 2.5rem;

        @include media('<tablet') {
            flex-direction: row;
            justify-content: flex-start;
        }
    }

    &--pdf {
        border-radius: .8rem;
        margin-top: 3.2rem;
        width: 100%;
    }

    &.hidden {
        display: none;
    }
}

.btn-wrapper {
    &--l-r {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .btn {
            margin-bottom: rem(10px);
        }

        @include media('<tablet') {
            justify-content: center;
        }
    }

    &--r {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;

        .btn {
            margin-bottom: rem(10px);
        }
    }

    &--center {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        .btn {
            margin: rem(10px);
        }
    }
}
