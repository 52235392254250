@import './list-utilities';
@import './responsive-utilities';
@import './text-utilities';
@import './visibility-utilities';

// unused
// @import './float-utilities';

/* ------------------------------------------------
- Ici, les helpers
------------------------------------------------ */

/// Automatically generate text color utilities class for each of our
/// defined contrib colors.
/// @example <div class="color--green"></div>

@if (variable-exists(contrib-colors)) {
    @each $color-name, $color-value in $contrib-colors {
        .color--#{$color-name} {
            color: $color-value !important;
        }
    }
}

/// Automatically generate background-color utilities class for each of our
/// defined contrib colors.
/// @example <div class="bg--green"></div>

@if (variable-exists(contrib-colors)) {
    @each $color-name, $color-value in $contrib-colors {
        .bg--#{$color-name} {
            background-color: $color-value !important;
        }
    }
}

/// Automatically generate margin-bottom utilities class for each of our
/// defined spacer.
/// @group spacing
/// @example .mb-spacer-xxl

@if (variable-exists(spacers)) {
    @each $spacer-name, $spacer-value in $spacers {
        .mb-#{$spacer-name} {
            margin-bottom: $spacer-value !important;
        }
    }
}
