$c: '.card';

#{$c} {
    background-color: #fff;
    padding: 1.6rem 1.2rem;
    border-radius: 1rem;
    margin-bottom: 1.6rem;
    height: 100%;
    display: flex;
    gap: 1.2rem;

    &-img {
        flex: 13.8rem 0 0;
        border-radius: .5rem;
        overflow: hidden;
        align-self: flex-start;
    }

    &-content {
        flex: 100% 1 1;
    }

    &-title {
        font-size: 1.6rem;
        color: var(--secondary-1);
        font-weight: 700;
        line-height: 1.5;
        margin: 0 0 .8rem;
    }

    &-desc {
        font-size: 1.4rem;

        p {
            margin: 0;
        }
    }

    &--small {
        box-shadow: 0 .4rem 1.6rem 0 rgba(193, 201, 214, .35);
        height: auto;

        #{$c} {
            &-img {
                flex: 10rem 0 0;
            }

            &-title {
                font-size: 1.4rem;
                font-weight: 600;
                color: var(--grey-800);
            }
        }
    }

    &--picto {
        //height: auto;

        #{$c} {
            &-img {
                flex: 4.1rem 0 0;
            }

            &-title {
                font-size: 1.4rem;
            }

            &-desc {
                color: var(--secondary-3);
            }
        }
    }
}
