@mixin fit-to-border($align: 'both') {
    --fitToBorder: calc(-1 * (100vw - 100% - var(--scrollWidth)) / 2);

    @if ($align == 'left') {
        margin-left: var(--fitToBorder);
    }

    @else if ($align == 'right') {
        margin-right: var(--fitToBorder);
    }

    @else if ($align == 'both') {
        margin-left: var(--fitToBorder);
        margin-right: var(--fitToBorder);
    }

    &.grid__cell {
        @if ($align == 'both') {
            width: calc(var(--width) - var(--fitToBorder) * 2);
        }

        @else {
            width: calc(var(--width) - var(--fitToBorder));
        }
    }
}

.fit-to-border {
    &--left {
        @include fit-to-border('left');
    }

    &--right {
        @include fit-to-border('right');
    }

    &--both {
        @include fit-to-border();
    }
}
