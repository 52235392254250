.modal {
    position: fixed;
    top: 0;
    height: calc(100vh - var(--header-h) - var(--footer-h));
    right: 0;
    left: 0;
    transition-duration: .2s;
    z-index: 1000;
    background-color: rgba(7, 22, 33, .2);
    opacity: 0;
    visibility: hidden;

    &.show {
        opacity: 1;
        visibility: visible;
    }

    @include media('<tablet') {
        bottom: 0;
        height: 100%;
    }

    &-dialog {
        background-color: #fff;
        //padding: 4.5rem;
        position: absolute;
        top: 0;
        bottom: 0;
        width: 48rem;
        display: flex;
        justify-content: center;
        align-items: center;
        transition-duration: .2s;
        right: -48rem;

        .show & {
            right: 0;
        }

        @include media('<tablet') {
            right: 0;
            width: 100%;
            top: auto;
            bottom: -100vh;
            height: calc(100vh - 13.6rem);
            border-radius: 3rem 3rem 0 0;
            padding: 5.2rem 3.2rem 5.6rem;

            .show & {
                bottom: 0;
            }

            &:before {
                content: '';
                position: absolute;
                top: 1.2rem;
                left: 0;
                right: 0;
                margin: auto;
                height: .4rem;
                background-color: var(--grey-300);
                border-radius: .2rem;
                width: 1.2rem;
            }
        }

        .close {
            position: absolute;
            top: 6rem;
            left: 2.2rem;
            background-color: transparent;
            border: 0;
            font-size: 2rem;
            color: var(--secondary-3);

            @include media('<tablet') {
                top: 3.2rem;
                right: 3.2rem;
                left: auto;
                font-size: 3rem;
            }
        }
    }

    &-content {
        width: 100%;
        height: 100%;
        padding: 4.5rem 4.5rem 4.5rem 5.5rem;
        overflow: auto;

        img {
            margin-bottom: 2rem;
        }

        p {
            margin-bottom: 0;
        }

        ul {
            margin: 0 0 2rem;
        }
    }
}
