/// Text align left
/// @group text-utility
.ta-l {
    text-align: left !important;
}

/// Text align right
/// @group text-utility
.ta-r {
    text-align: right !important;
}

/// Text align center
/// @group text-utility
.ta-c {
    text-align: center;
}