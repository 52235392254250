$c: '.footer';

#{$c} {
    background-color: var(--grey-800);
    color: #{$white};

    .inner {
        padding: 1.5rem 4.5rem;
        display: flex;
        justify-content: space-between;

        @include media('<=tablet') {
            flex-direction: column;
            padding: 3.2rem 0;
        }

        &:before {
            @include media('>medium') {
                content: '';
            }
        }

        .footer-list--right,
        &:before {
            @include media('>medium') {
                flex: 17rem 0 0;
            }
        }

        .footer-list {
            margin: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 2.5rem;
            font-size: 1.1rem;

            @include media('<=tablet') {
                gap: 1.2rem;
                flex-direction: column;
            }

            &--centered {
                @include media('>medium') {
                    flex: 100% 1 1;
                    margin: 0 2.5rem;
                }

                @include media('<=tablet') {
                    margin-bottom: 2.4rem;
                }
            }

            a {
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                    color: #{$white};
                }
            }
        }
    }
}
