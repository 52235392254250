/*
 * vars.scss
 */

/* ============================== */
/* Colors */
/* ============================== */
$black: #000;
$darkest-grey: #363636;
$dark-grey: #5d5d5d;
$grey: #999;
$light-grey: #d8d8d8;
$lightest-grey: #f8f8f8;
$white: #fff;
$text-placeholder: #6a6a6a;

$color-text: $dark-grey;
$color-info: #fae692;
$color-alert: #e38e0e;
$color-error: #c00;
$color-success: #007f00;
$color-placeholder: $text-placeholder;
$color-link-hover: $grey;
$color-link-hover-dark-bg: $light-grey;
$color-border: $grey;

/// <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50"><rect fill="#00F" x="0" y="0" width="100%" height="100%"/></svg>
/// @group colors
$color-primary: #007461;
/// <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50"><rect fill="#ffc617" x="0" y="0" width="100%" height="100%"/></svg>
/// @group colors
$color-secondary: #308276;
/// <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50"><rect fill="#3a8f3a" x="0" y="0" width="100%" height="100%"/></svg>
/// @group colors
$color-tertiary: #3a8f3a;

$color-focus: $color-primary;
$color-text-selection: $color-primary;

/// To generate background-color, or color helpers classes
/// @example bg--black, color--black
/// @source _helpers.scss
/// @group colors
$contrib-colors: (
    'black': $black,
    'white': $white,
    'dark-grey': $dark-grey,
    'grey': $grey,
    'light-grey': $light-grey,
    'primary': $color-primary,
    'secondary': $color-secondary,
    'tertiary': $color-tertiary,
    'success': $color-success,
    'error': $color-error,
    'grey-200': #f7f8fa,
    'grey-800': #071621,
    'transparent': transparent,
    'secondary-1': #308276,
    'secondary-3': #004652,
    'accent-2': #0a94a8,
);

$dark-backgrounds: '.bg--primary, .bg--black';

/* ============================== */
/* Spacers */
/* ============================== */

/// @group spacing
$spacer-unit: 8px;
/// 8px
/// @group spacing
$spacer-xxs: 1 * $spacer-unit;
/// 12px
/// @group spacing
$spacer-xs: 1.5 * $spacer-unit;
/// 16px
/// @group spacing
$spacer-s: 2 * $spacer-unit;
/// 24px
/// @group spacing
$spacer-m: 3 * $spacer-unit;
/// 32px
/// @group spacing
$spacer-m2: 4 * $spacer-unit;
/// 48px
/// @group spacing
$spacer-l: 6 * $spacer-unit;
/// 56px
/// @group spacing
$spacer-xl: 7 * $spacer-unit;
/// 64px
/// @group spacing
$spacer-xxl: 8 * $spacer-unit;

// To generate helper classes
$spacers: (
    xxs: $spacer-xxs,
    xs: $spacer-xs,
    s: $spacer-s,
    m: $spacer-m,
    m2: $spacer-m2,
    l: $spacer-l,
    xl: $spacer-xl,
    xxl: $spacer-xxl,
);

/* ============================== */
/* Fonts
/* ============================== */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

$root-font-size-ie11: calc(1em * .625) !default; /* 10px, IE9-IE11 math fixing. See http://bit.ly/1g4X0bX */
$root-font-size: 10px !default; /* 10px, IE9-IE11 math fixing. See http://bit.ly/1g4X0bX */
/// @group typography
$base-font-size: 18px !default;
/// @group typography
$default-fonts: 'Open Sans', Arial, sans-serif;

/* ============================== */
/* Breakpoints
/* ============================== */
///@group responsive
$large: 1560px;
///@group responsive
$desktop: 1366px;
///@group responsive
$medium: 1024px;
///@group responsive
$tablet: 768px;
///@group responsive
$mobile: 480px;

/// variable utilisée par le module "include media"
/// @group responsive
/// @link https://eduardoboucas.github.io/include-media/
/// @prop {value in px} mobile [480px]
/// @prop {value in px} tablet [768px]
/// @prop {value in px} medium [1024px]
/// @prop {value in px} desktop [1366px]
/// @prop {value in px} large [1560px]
$breakpoints: (
    mobile: $mobile,
    tablet: $tablet,
    medium: $medium,
    desktop: $desktop,
    large: $large,
);

$gutter-w: $spacer-m;

/* ============================== */
/* Avalanche grid system
/* ============================== */

/// Espace entre les colonnes
/// @group grid
/// @link http://colourgarden.github.io/avalanche/
$av-gutter: $gutter-w;

/// colonnes disponibles. Attention, leur nombre peut faire varier de manière significative la taille du css généré
/// @group grid
/// @link http://colourgarden.github.io/avalanche/
$av-widths: (
    1,
    2,
    3,
    4,
    // 5,
    // 6,
    // 12
);

/// Breakpoints
/// @group grid
/// @link http://colourgarden.github.io/avalanche/
$av-breakpoints: (
    'mobile': 'screen and (min-width: #{$mobile})',
    'tablet': 'screen and (min-width: #{$tablet})',
    'medium': 'screen and (min-width: #{$medium})',
    'desktop': 'screen and (min-width: #{$desktop})',
);

$av-enable-grid-center: true;
$av-enable-grid-right: true;
$av-enable-grid-cell-center: true;
$av-enable-grid-flush: true;
$av-enable-grid-bottom: true;
$av-enable-grid-middle: true;
$av-enable-grid-small: true;
$av-enable-grid-large: true;
$av-enable-grid-huge: true;
$av-enable-grid-rev: true;

// Icomoon
$icomoon-font-path: './../fonts/icomoon' !default;

//Site content
/// Site content max-width
///@group layout
$sc-max-width: 1280px;
/// Site content x-padding
///@group layout
$sc-x-padding: 20px;
/// Site content x-padding
///@group layout
$sc-x-padding-s: 20px;

// button radius
$btn-radius: 22px;

// global radius
$global-radius: 8px;

:root {
    --scrollWidth: 0px;
    --header-h: 60px;
    --footer-h: 46px;

    --step: 1;
    --quiz-h: calc(100vh - var(--footer-h) - var(--header-h));

    --primary: #007461;
    --secondary-1: #308276;
    --secondary-2: #a0d7a2;
    --secondary-3: #004652;
    --secondary-4: #d8ead7;
    --secondary-5: #14a495;
    --secondary-6: #f2f9fa; //???
    --accent: #e62f44;
    --accent-2: #0a94a8;

    --grey-200: #f7f8fa;
    --grey-300: #ecedf0;
    --grey-700: #666e8a;
    --grey-800: #071621;
}

.is-chromium {
    --scrollWidth: 6px;
}
